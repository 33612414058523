<template>
  <div class="row">
    <div class="col-lg-12 mb-3 formathistory">
      <h4 class="mb-1"><i class="fal fa-book-open mr-2"></i> Invoice Notes - {{history.invnum}}</h4>
      <h5 class="mb-3">{{history.vcn}} ({{history.vfname}} {{history.vlname}})</h5>
      <h6 class="mb-1" v-if="history.quickpay_total > 0">Quickpay Subtotal: ${{formatPrice(history.quickpay_total - tax_qp)}}</h6>
      <h6 class="mb-1" v-if="history.quickpay_total > 0">Quickpay (GST): ${{formatPrice(history.quickpay_total)}}</h6>
      <h6 class="mb-1">Subtotal: ${{formatPrice(subtotalComputed)}}</h6>
      <h6 class="mb-4">Total (GST): ${{formatPrice(history.total)}}</h6>

      <div v-if="allHistorySorted.length > 0 && history.accountant_mdate">
        <p class="mb-0" v-if="history.accountant_paid === 1"><strong>Paid On:</strong> {{history.accountant_mdate | moment("MM/DD/YYYY hh:mm A") }}</p>
        <p class="mb-0" v-if="history.ac_note"  v-show="!editChequeNumbers">Accountant Note: <pre>{{history.ac_note}}</pre></p>
        <p class="mb-1" v-if="history.check_round_date && !editChequeNumbers"><strong>Cheque Round Date: {{history.check_round_date | moment("MM/DD/YYYY")}} | Cheque Number: {{history.check_number}}</strong></p>
        <!-- <button class="btn btn-primary btn-sm ml-2" @click="editCheque" v-if="this.$session.get('user') == 'accountant'"><i class="fa fa-edit"></i></button> -->
        <p v-if="!history.check_cleared" style="color: #c00606;"><strong><i style="color: #c00606;" class="fas fa-exclamation-triangle"></i><em>The payment information of this invoice is being changed.</em></strong></p>

        <!-- <div class="row" v-if="editChequeNumbers">
          <div class="col-md-12 mt-2">
            <h5>Comments <i class="ml-2 fa fa fa-question-circle" v-b-tooltip.hover title="Comments will be seen by all user levels and be attached on invoice"></i></h5>
            <textarea name="comments" v-model="history.ac_note" class="w-100 rounded border mt-2 mb-2" rows="5"></textarea>
          </div>
          <div class="col-md-12">
            <label for="">Cheque Round Date:</label>
            <datepicker class="rt-date-picker-project rt-datepicker" style="" name="p_start_date" placeholder="Dropdown" format="yyyy-MM-dd" v-model="history.check_round_date"></datepicker>
          </div>
          <div class="col-md-12">
            <label  for="">Cheque Number:</label>
            <input class="form-control" type="text"  style="width: 200px; " placeholder="Cheque Number" v-model="history.check_number" value="">
            <button type="submit" @click="updateInvoice()" class="btn btn-success mt-3 mb-3"><i class="fa fa-check"></i> Update</button>
          </div>
        </div> -->
      </div>
      <div v-if="allHistorySorted.length > 0 && history.inv_passed == 7 || history.inv_passed == 11 || history.inv_passed == 21 || history.inv_passed == 31 || history.inv_passed == 41 || history.inv_passed == 51">
        <p class="mb-0"><strong>Final Approval</strong> - Ready For Payment</p>
      </div>
      <div v-for="(line, i) in allHistorySorted">
        <!-- {{i}}-- {{allHistorySorted.length - 1}} {{allHistorySorted}}<strong v-if="i == allHistorySorted.length - 1">Submitted On:</strong> -->
        <p class="mb-0"><strong :class="{danger: line.status == 'Rejected', success: line.status == 'Approved'}">{{line.status}} On:</strong> {{line.date | moment("MM/DD/YYYY hh:mm:ss A") }} by {{line.user}}</p>
        <p class="mb-2" v-if="line.comment && showEditComment != i"><!-- {{line.user}} Note:-->
          <pre>{{line.comment}}</pre>
          <!-- <b-button v-if="$session.get('user') == 'manager' && line.user == 'Management' && line.invoice" @click="editComment(line, i)" class="btn-sm" variant="primary">
            <i class="fa fa-edit"></i>
          </b-button>
          <b-button v-if="$session.get('user') == 'pm' && line.user == 'Project Manager' && line.invoice" @click="editComment(line, i)" class="btn-sm" variant="primary">
            <i class="fa fa-edit"></i>
          </b-button> -->
        </p>
        <!-- <div v-if="i == showEditComment">
        <textarea v-model="newComment" rows="3" class="w-100"></textarea>
          <b-button v-if="$session.get('user') == 'pm' && line.user == 'Project Manager' && line.invoice" @click="editComment(line, i)" class="btn-sm" variant="primary">
            <i class="fa fa-edit"></i>
          </b-button>
        </div> -->
      </div>
    </div>
    <div class="col-lg-12 mt-3" v-if="invoiceFiles.length > 0">
      <h4 class="mb-3"><i class="fal fa-file mr-2"></i> Invoice Files</h4>
      <p v-for="file in invoiceFiles"><a href="javascript:void(0);" @click="getFile(file.filename)">{{file.filename}}</a><span v-if="history.inv_passed == 2 || history.inv_passed == 5 || history.inv_passed == 14 || history.inv_passed == 24 || history.inv_passed == 34 || history.inv_passed == 44 || history.inv_passed == 54"><button v-if="$session.get('user') == 'vendor' || $session.get('user') == 'accountant'" type="button" @click="deleteFile(file)" class="btn btn-sm btn-danger ml-2" name="button">Remove File <i class="fa fa-minus-circle"></i></button></span></p>
    </div>
    <div class="col-lg-12" v-if="invoiceModificationHistoryChanges.length > 0">
      <h4 class="mb-3"><i class="fal fa-clock mr-2"></i> Invoice Changes</h4>
      <div class="row ml-0 mr-0 text-center">
        <div class="col-xl-4" style="background: #fff97a45; padding-top: 15px;">
          <h4 class="mb-0 pb-0">Updates <i class="fa fa-edit"></i></h4>
        </div>
        <div class="col-xl-4" style="background: #4bdf603d; padding: 15px;">
          <h4 class="mb-0 pb-0">Additions <i class="fa fa-plus"></i></h4>
        </div>
        <div class="col-xl-4" style="background: #b9353540; padding: 15px;">
          <h4 class="mb-0 pb-0">Removals <i class="fa fa-minus-circle"></i></h4>
        </div>
      </div>
      <div class="row ml-0 mr-0 text-center" style="border-bottom: 2px solid #444;border-top: 2px solid #444;" v-for="group in invoiceModificationHistoryChanges">
        <div class="col-xl-12" style="background: #f9f9f9">
          <h5 class="mb-0 py-2">Resubmission Or Saved As Draft Date: {{group.date | moment("MM/DD/YYYY hh:mm:ss A")}}</h5>
        </div>
        <div class="col-xl-4" style="background: #fff97a45; padding-top: 15px;">
          <!-- <h5 class="mb-3">Updates <i class="fa fa-edit"></i></h5> -->
          <div class="mb-3" v-for="(change, i) in group.groupedUpdatedServices" v-if="group.groupedUpdatedServices.length > 0">
            <p class="mb-0"><strong><span class="text-capitalize">{{change.name}}</span></strong></p>
            <pre><p class="mb-0" v-for="line in change.code"><span v-if="line.comment">{{line.comment}}</span></p></pre>
          </div>
        </div>
        <div class="col-xl-4" style="background: #4bdf603d; padding: 15px;">
          <!-- <h5 class="mb-3">Additions <i class="fa fa-plus"></i></h5> -->
          <p v-for="(line, i) in group.addedServices" v-if="group.addedServices.length > 0" class="mb-0"><strong><span class="text-capitalize">{{line.service_name}}</span></strong>:<pre><span v-if="line.comment">${{line.comment}}</span></pre></p>
        </div>
        <div class="col-xl-4" style="background: #b9353540; padding: 15px;">
          <!-- <h5 class="mb-3">Removals <i class="fa fa-minus-circle"></i></h5> -->
          <p v-for="(line, i) in group.removedServices" v-if="group.removedServices.length > 0" class="mb-0"><strong><span class="text-capitalize">{{line.service_name}}</span></strong>:<pre><span v-if="line.comment">${{line.comment}}</span></pre></p>
        </div>
      </div>
    </div>
  </div>

</template>

<script type="text/javascript">
import InvoiceHistory from './../invoice/InvoiceHistory'
import Datepicker from 'vuejs-datepicker'
import fileDownload from 'js-file-download';

export default {
  name: 'InvoiceHistory',
  props: ['history'],
  components: {
    Datepicker,
  },
  data: function() {
    return {
      invoiceHistory: "",
      allHistory: [],
      invoiceModificationHistoryChanges: [],
      editChequeNumbers: false,
      invoiceFiles: [],
      newComment: "",
      showEditComment: null,
      user: null
    }
  },
  computed: {
    allHistorySorted: function(){
      var removeDuplicates = this.allHistory;
      var comp = "date";
      var arr = this.allHistory;
      const unique = arr
        .map(e => e[comp])
        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)
        // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);
console.log(this.lodash.sortBy(unique, 'date').reverse(),'sortedHistory');
      return this.lodash.sortBy(unique, 'date').reverse();
    },
    subtotalComputed: function() {
      return this.history.total - this.tax;
    },
    tax: function(){
      return this.history.total - (this.history.total / ((this.history.tax_percentage/100) + 1))
    },
    tax_qp: function(){
      return this.history.quickpay_total - (this.history.quickpay_total / ((this.history.tax_percentage/100 ) + 1))
    },
  },
  methods: {
    editComment(line, i){
      this.newComment = line.comment;
      this.showEditComment = i;
    },
    updateComment(){
      if(line.user == 'Project Manager'){
        let data = JSON.stringify({
          invoiceid: this.history.invoiceid,
          wts_note: this.newComment,
        })
        this.$http.post('/wts/edit/note', data)
        .then(response => {
          this.$toasted.show('Comment Updated Successfully', {type: 'success', duration: '3000'});
          this.showEditComment = null;
        })
      }
      else {
        this.$http.get('/manager/get/profile')
        .then(response => {
          let data = JSON.stringify({
            invoiceid: this.history.invoiceid,
            wts_note: this.newComment,
          })
          this.$http.post('/wts/edit/note', data)
          .then(response2 => {
            this.$toasted.show('Comment Updated Successfully', {type: 'success', duration: '3000'});
            this.showEditComment = null;
          })
        })
      }
    },
    updateInvoice() {
      let data = JSON.stringify({
        invoiceid: this.history.invoiceid,
        projectid: this.history.projectid,
        accountant_paid: 1,
        ac_note: this.history.ac_note,
        check_round_date: this.history.check_round_date,
        check_number: this.history.check_number
      })
      this.$http.post('/accountant/edit/invoice', data)
      .then(response => {
        this.$toasted.show('Invoice Updated Successfully', {type: 'success', duration: '3000'});
        this.$router.push('/accountant')
        this.editChequeNumbers = false;
      })
      .catch(error => {
        this.invoiceError = true;
      })
    },
    editCheque() {
      this.editChequeNumbers = true;
    },
    loadHistory() {
      if(this.history.invoiceid != undefined){
        var user = this.$session.get('user');
        var url = '/' + user + '/get/invoice_history/' + this.history.invoiceid
        if(user == 'pm'){
          user = "wts";
        }
        if(user == 'accountant'){
          user = "manager";
        }
        if(user == 'wts' || user == 'manager' || user == 'wss'){
          url = '/' + user + '/get/invoice_history/' + this.history.projectid + "/" + this.history.invoiceid
        }

        this.$axios.get('/invoice_modification_history/get/' + this.history.invoiceid)
        .then(response => {
          // var result = response.data.result.filter(x=>x.edit_type != "D-UPDATE" && x.edit_type != "D-ADD" && x.edit_type != "D-DELETE")
          // console.log(result)
          //var result = this.lodash.sortBy(response.data.result, 'creation_date')
          var result = response.data.result;

          this.invoiceModificationHistoryChanges = this.lodash.chain(result.reverse())
          .groupBy('creation_date')
          .map((code, date) => ({date, code }))
          .value();

          for (var i = 0; i < this.invoiceModificationHistoryChanges.length; i++) {
            if(this.invoiceModificationHistoryChanges[i].code[0].edit_type != "D-UPDATE" || this.invoiceModificationHistoryChanges[i].code[0].edit_type != "D-DELETE" || this.invoiceModificationHistoryChanges[i].code[0].edit_type != "D-ADD"){
              this.invoiceModificationHistoryChanges[i].groupedUpdatedServices = this.lodash.chain(this.invoiceModificationHistoryChanges[i].code.filter(x=>x.edit_type == 'UPDATE'))
              .groupBy('service_name')
              .map((code, name) => ({name, code }))
              .value();

              this.invoiceModificationHistoryChanges[i].removedServices = this.invoiceModificationHistoryChanges[i].code.filter(x=>x.edit_type == 'DELETE');
              this.invoiceModificationHistoryChanges[i].addedServices = this.invoiceModificationHistoryChanges[i].code.filter(x=>x.edit_type == 'ADD');
            }
            else {
              console.log('hello')
            }
          }
        })
        .catch(error => {
          console.log(error)
        })

        this.$axios.get(url).then(response => {
          console.log(response.data.result,"response for history");
          for (var i = 0; i < response.data.result.length; i++) {
            var comment = response.data.result[i].history_string;

            if(comment.mgr5_mdate != null){
              var status = "Approved";
              var date = comment.mgr5_mdate;
              if(comment.inv_passed == 54){
                status = "Rejected";
              }
              if(comment.mgr5_mdate.fn == "NOW"){
                date = response.data.result[i].creation_date;
              }
              this.allHistory.push({
                "status": status,
                "comment": comment.mgr5_note,
                "date": date,
                "user": "Management"
              })
            }
            if(comment.mgr4_mdate != null){
              var status = "Approved";
              var date = comment.mgr4_mdate;
              if(comment.inv_passed == 44){
                status = "Rejected";
              }
              if(comment.mgr4_mdate.fn == "NOW"){
                date = response.data.result[i].creation_date;
              }
              this.allHistory.push({
                "status": status,
                "comment": comment.mgr4_note,
                "date": date,
                "user": "Management"
              })
            }
            if(comment.mgr3_mdate != null){
              var status = "Approved";
              var date = comment.mgr3_mdate;
              if(comment.inv_passed == 34){
                status = "Rejected";
              }
              if(comment.mgr3_mdate.fn == "NOW"){
                date = response.data.result[i].creation_date;
              }
              this.allHistory.push({
                "status": status,
                "comment": comment.mgr3_note,
                "date": date,
                "user": "Management"
              })
            }
            if(comment.mgr2_mdate != null){
              var status = "Approved";
              var date = comment.mgr2_mdate;
              if(comment.inv_passed == 24){
                status = "Rejected";
              }
              if(comment.mgr2_mdate.fn == "NOW"){
                date = response.data.result[i].creation_date;
              }
              this.allHistory.push({
                "status": status,
                "comment": comment.mgr2_note,
                "date": date,
                "user": "Management"
              })
            }
            if(comment.mgr1_mdate != null){
              var status = "Approved";
              var date = comment.mgr1_mdate;
              if(comment.inv_passed == 14){
                status = "Rejected";
              }
              if(comment.mgr1_mdate.fn == "NOW"){
                date = response.data.result[i].creation_date;
              }
              this.allHistory.push({
                "status": status,
                "comment": comment.mgr1_note,
                "date": date,
                "user": "Management"
              })
            }

            if(comment.vendor_mdate != null){
              this.allHistory.push({
                "status": "Resubmitted",
                "comment": comment.vendor_note,
                "date": comment.vendor_mdate,
                "user": "Vendor"
              })
            }
            if(comment.wss_mdate != null){
              var status = "Approved";
              if(comment.inv_passed == 9){
                status = "Rejected";
              }
              this.allHistory.push({
                "status": status,
                "comment": comment.wss_note,
                "date": comment.wss_mdate,
                "user": "Well Site Supervisor"
              })
            }
            if(comment.wts_mdate != null){
              var date = comment.wts_mdate;
              var status = "Rejected";
              if(comment.inv_passed == 6 ||
                comment.inv_passed == 7 ||
                comment.inv_passed >= 11 ) {
                status = "Approved";
              }
              if(comment.wts_mdate.fn == "NOW"){
                date = response.data.result[i].creation_date;
              }
              this.allHistory.push({
                "status": status,
                "comment": comment.wts_note,
                "date": date,
                "user": "Project Manager"
              })
            }

          }

          if(this.history.mgr5_mdate != null){
            // var status = "Rejected";
            var status;
            if(this.history.mgr_stat_5 == 1){
              status = "Approved";
            }
            if(this.history.mgr_stat_5 == 2){
              status = "Rejected";
            }
            this.allHistory.push({
              "status": status,
              "comment": this.history.mgr5_note,
              "date": this.history.mgr5_mdate,
              "user": "Management",
              "invoice": true,
              "manager": 5
            })
          }
          if(this.history.mgr4_mdate != null){
            // var status = "Rejected";
            var status;
              if(this.history.mgr_stat_4 == 1){
              status = "Approved";
            }
            if(this.history.mgr_stat_4 == 2){
              status = "Rejected";
            }
            this.allHistory.push({
              "status": status,
              "comment": this.history.mgr4_note,
              "date": this.history.mgr4_mdate,
              "user": "Management",
              "invoice": true,
              "manager": 4
            })
          }

          if(this.history.mgr3_mdate != null){
            // var status = "Rejected";
            var status;
             if(this.history.mgr_stat_3 == 1){
              status = "Approved";
            }
            if(this.history.mgr_stat_3 == 2){
              status = "Rejected";
            }
            this.allHistory.push({
              "status": status,
              "comment": this.history.mgr3_note,
              "date": this.history.mgr3_mdate,
              "user": "Management",
              "invoice": true,
              "manager": 3
            })
          }
          if(this.history.mgr2_mdate != null){
            // var status = "Rejected";
            var status;
              if(this.history.mgr_stat_2 == 1){
              status = "Approved";
            }
            if(this.history.mgr_stat_2 == 2){
              status = "Rejected";
            }
            this.allHistory.push({
              "status": status,
              "comment": this.history.mgr2_note,
              "date": this.history.mgr2_mdate,
              "user": "Management",
              "invoice": true,
              "manager": 2
            })

          }
          if(this.history.mgr1_mdate != null){
            // var status = "Rejected";
            var status;
              if(this.history.mgr_stat_1 == 1){
              status = "Approved";
            }
            if(this.history.mgr_stat_1 == 2){
              status = "Rejected";
            }

            this.allHistory.push({
              "status": status,
              "comment": this.history.mgr1_note,
              "date": this.history.mgr1_mdate,
              "user": "Management",
              "invoice": true,
              "manager": 1
            })
          }

          if(this.history.wts_mdate != null){
            // var status = "Approved";
            var status;
            if(this.history.wts_stat == 1){
              status = "Approved";
            }
            if(this.history.wts_stat == 2){
              status = "Rejected";
            }
            this.allHistory.push({
              "status": status,
              "comment": this.history.wts_note,
              "date": this.history.wts_mdate,
              "user": "Project Manager",
              "invoice": true
            })
          }
          if(this.history.wss_mdate != null){
              var status;
              if(this.history.wss_stat == 1){
                  status = "Approved";
                }
                if(this.history.wss_stat == 2){
                  status = "Rejected";
                }

              this.allHistory.push({
                "status": status,
                "comment": this.history.wss_note,
                "date": this.history.wss_mdate,
                "user": "Well Site Supervisor",
                "invoice": true
              })
          }
            if(this.history.ac_mdate != null) {
                var status;
                if(this.history.ac_stat == 1){
                   status = "Approved";
                }
                if(this.history.ac_stat == 2){
                  status = "Rejected";
                }
                this.allHistory.push({
                  "status": status,
                  "comment": this.history.ac_note,
                  "date": this.history.ac_mdate,
                  "user": "Accountant",
                  "invoice": true
                })
          }

          if(this.history.vendor_mdate != null){
            var status = "Submitted";
            if(response.data.result.length > 0){
              status = "Resubmitted";
            }
            this.allHistory.push({
              "status": status,
              "comment": this.history.vendor_note,
              "date": this.history.vendor_mdate,
              "user": "Vendor",
              "invoice": true
            })
          }
          //   if(this.history.ac_stat == 2){
          //   var status = "Rejected";
          //   this.allHistory.push({
          //     "status": status,
          //     "comment": this.history.ac_note,
          //     "date": this.history.accountant_mdate,
          //     "user": "Accountant",
          //     "invoice": true
          //   })
          // }
        });
      }
    },
    loadFiles(){
      if(this.history.invoiceid != undefined){
        this.$http.get('/attachment/get_attachment_table/' + this.history.invoiceid + '/' + this.history.projectid)
        .then(response => {
          this.invoiceFiles = response.data.results;
        })
        .catch(error => {
          this.invoiceError = true;
        })
      }
    },
    getFile(filename){
      this.$http.get('/attachment/get_attachment/' + filename + '/' + this.history.projectid + '/' + this.history.invoiceid, {
        responseType: 'blob',
      })
      .then(response => {
        fileDownload(response.data, filename);
      })
      .catch(error => {
        console.log(error)
      })
    },
    deleteFile(file){
      let data = JSON.stringify({
        invoiceid: this.history.invoiceid,
        filename: file.filename,
        companyid:  this.history.companyid,
        projectid: this.history.projectid
      })
      this.$http.post('/attachment/delete/', data)
      .then(response => {
        this.invoiceFiles = this.invoiceFiles.filter(x=>x.filename != file.filename)
        this.$toasted.show('File deleted successfully', {type: 'success', duration: '3000'});
      })
      .catch(error => {
        console.log(error)
      })
    }
  },
  mounted() {
    this.loadHistory();
    this.loadFiles();
  },
  watch: {
    history: function(){
      this.loadHistory();
      this.loadFiles();
    }
  },
}
</script>

<style media="screen">
  .danger {
    color: var(--danger);
  }
  .success {
    color: var(--success);
  }
  .formathistory pre{
    font-family: proxima-nova, helvetica, sans-serif;
    font-size : 1em !important;
  }
</style>
